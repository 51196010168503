import React from 'react';

interface AuthContextType {
    user: any;
    accessToken: any;
    refreshToken: any;
    signin: (email: string, password: string, cb: VoidFunction) => void;
    signout: (cb: VoidFunction) => void;
    loggedIn: () => boolean;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
