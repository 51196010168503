import {AppBar, Toolbar, Box, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../Auth/AuthContext';
import {Search} from '../Smoelen/Search';

type TopBarProps = {};

export const TopBar: React.FC<TopBarProps> = () => {
    const auth = React.useContext(AuthContext);

    function renderSearchbar() {
        if (auth.loggedIn()) {
            return <Search />;
        }

        return null;
    }

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed" style={{background: '#d0211c'}}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Smoelenboek
                    </Typography>
                    {renderSearchbar()}
                </Toolbar>
            </AppBar>
        </Box>
    );
};
