import React from 'react';
import './App.css';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {TopBar} from './Components/Navigation/TopBar';
import {AuthContext} from './Auth/AuthContext';
import {TabBar} from './Components/Navigation/TabBar';
import {createTheme, ThemeProvider} from '@mui/material';
import themeOptions from './Theme';

const App = () => {
    const auth = React.useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const [visible, setVisible] = React.useState<boolean>(false);

    const theme = React.useMemo(
        () =>
            createTheme({
                ...themeOptions,
                palette: {
                    ...themeOptions.palette,
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    React.useEffect(() => {
        if (auth.loggedIn()) {
            setVisible(true);
        } else {
            setVisible(false);
        }

        if (location.pathname === '/') {
            navigate('/home');
        }
    }, [location]);

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <TopBar />
                <div
                    style={{
                        paddingTop: '70px',
                        paddingBottom: '70px',
                    }}>
                    <Outlet />
                </div>
                {visible && <TabBar />}
            </div>
        </ThemeProvider>
    );
};

export default App;
