import {
    Avatar,
    Card,
    CardContent,
    Divider,
    ListItemIcon,
    ListItemText,
    List,
    ListItem,
    Typography,
    ListSubheader,
} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import apiclient from '../Utilities/apiclient';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Mail from '@mui/icons-material/Mail';
import {
    Add,
    CakeOutlined,
    Numbers,
    Phone,
    SportsVolleyball,
} from '@mui/icons-material';
import moment from 'moment';
import {Season as SeasonList} from '../Components/Smoelen/Season';

type ProfileProps = {};

interface UserDataInterface {
    backNumber: number | null;
    bankaccountNumber: string;
    birthDate: string;
    bondNumber: string;
    city: string;
    email: string;
    firstName: string;
    houseNumber: string;
    id: number;
    joinDate: string;
    lastName: string;
    leaveDate: string | null;
    phoneNumber: string;
    postcode: string;
    streetName: string;
    seasons: Seasons;
    profilePicture?: string;
}

interface CommitteeTeam {
    id: number;
    function: string;
    team?: any;
    committee?: any;
}

export interface Seasons {
    id: string;
    name: string;
    data: Array<CommitteeTeam>;
}

export const Profile: React.FC<ProfileProps> = () => {
    const params = useParams();

    const [image, setImage] = React.useState<string>('');
    const [data, setData] = React.useState<UserDataInterface>();
    const [seasons, setSeasons] = React.useState<Array<Seasons>>([]);

    React.useEffect(() => {
        apiclient.get(`user/${params.userId}`).then(res => {
            setImage(res.data.profilePicture);
            setData(res.data);

            const keys = Object.keys(res.data.seasons);
            keys.reverse();
            let x = [];

            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];

                x.push(res.data.seasons[key]);
            }

            setSeasons(x);
        });
    }, []);

    function render() {
        let elements: Array<JSX.Element> = [];

        if (seasons.length > 0) {
            for (let season of seasons) {
                elements.push(<SeasonList key={season.id} data={season} />);
            }
        }

        return elements;
    }

    return (
        <div className="flex justify-items flex-col">
            <Card className="w-5/6 mx-auto">
                <CardContent>
                    <Avatar
                        className="mx-auto"
                        variant="square"
                        src={`${apiclient.defaults.baseURL}/public/${image}`}
                        sx={{width: 200, height: 200}}
                    />
                    <Typography
                        variant="h5"
                        className="text-center"
                        sx={{marginTop: 3}}>
                        {data?.firstName} {data?.lastName}
                    </Typography>
                    <Typography
                        variant="h6"
                        className="text-center"
                        sx={{color: '#d0221c'}}>
                        <FmdGoodIcon /> {data?.streetName} {data?.houseNumber}
                    </Typography>
                    <Divider sx={{marginTop: 2, marginBottom: 2}} />
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <Mail color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Email'}
                                secondary={data?.email ?? ''}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Phone color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Telefoonnummer'}
                                secondary={data?.phoneNumber ?? ''}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CakeOutlined color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Verjaardag'}
                                secondary={
                                    moment(data?.birthDate).format(
                                        'D-MM-YYYY',
                                    ) ?? ''
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SportsVolleyball color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Bondnummer'}
                                secondary={data?.bondNumber ?? ''}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Add color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Inschrijfdatum'}
                                secondary={
                                    moment(data?.joinDate).format(
                                        'D-MM-YYYY',
                                    ) ?? ''
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Numbers color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Rug nummer'}
                                secondary={data?.backNumber ?? ''}
                            />
                        </ListItem>
                    </List>
                    <List
                        sx={{bgcolor: 'background.paper'}}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={<ListSubheader>Activiteiten</ListSubheader>}>
                        {render()}
                    </List>
                </CardContent>
            </Card>
        </div>
    );
};
