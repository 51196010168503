import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import history from '../Components/Navigation/History';

const baseUrl = 'https://protos.nouw.net';

const instance = axios.create({
    baseURL: baseUrl,
});

instance.interceptors.request.use(async config => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        return {
            ...config,
            headers: {
                Authorization: accessToken,
            },
        };
    }

    localStorage.clear();
    history.replace('/login');

    return config;
});

instance.interceptors.response.use(
    response => response,
    async error => {
        const status = error.response ? error.response.status : null;
        const originalConfig = error.config;

        if (status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            const refreshToken = localStorage.getItem('refreshToken');

            if (!refreshToken) {
                history.replace('/login');
                localStorage.clear();
                return Promise.reject(error);
            }

            const rs = await axios.post(`${baseUrl}/auth/refresh`, {
                refreshToken,
            });

            const {accessToken} = rs.data;

            localStorage.setItem('accessToken', accessToken);
            console.log(originalConfig);
            return instance(originalConfig);
        }

        return Promise.reject(error);
    },
);

export default instance;
