import {Card, CardContent, CardMedia, Typography} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {User} from '../../Components/Smoelen/User';
import apiclient from '../../Utilities/apiclient';

type InfoProps = {};

interface Member {
    id: number;
    function: string;
    user: {
        id: number;
        firstName: string;
        lastName: string;
        profilePicture: string;
    };
}

export const Info: React.FC<InfoProps> = () => {
    const params = useParams();

    const [image, setImage] = React.useState<string>('');
    const [name, setName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [members, setMembers] = React.useState<Array<Member>>([]);

    React.useEffect(() => {
        apiclient.get(`committee/info/${params.commissieId}`).then(res => {
            setImage(res.data.committee.image);
            setName(res.data.committee.name);
            setEmail(res.data.committee.email);
            setMembers(res.data.users);
        });
    }, []);

    function render() {
        let elements: Array<JSX.Element> = [];

        for (let member of members) {
            console.log(member);
            elements.push(
                <User
                    key={member.id}
                    name={`${member.user.firstName} ${member.user.lastName}`}
                    profilePicture={member.user.profilePicture}
                    subTitle={member.function}
                />,
            );
        }

        return elements;
    }

    return (
        <div className="flex">
            <Card className="w-5/6 mx-auto">
                <CardMedia
                    component="img"
                    src={`${apiclient.defaults.baseURL}/public/${image}`}
                />
                <CardContent>
                    <Typography variant="h5">{name}</Typography>
                    <Typography color="text.secondary">{email}</Typography>
                    <div className="grid lg:grid-cols-2">{render()}</div>
                </CardContent>
            </Card>
        </div>
    );
};
