import {Card, CardContent, CardMedia, Divider, Typography} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {User} from '../../Components/Smoelen/User';
import apiclient from '../../Utilities/apiclient';
type InfoProps = {};

export interface TeamData {
    id: number;
    function: string;
    user: {
        id: number;
        firstName: string;
        lastName: string;
    };
    team: {
        name: string;
        rank: string;
    };
}

interface Player {
    id: number;
    function: string;
    user: {
        id: number;
        firstName: string;
        lastName: string;
        profilePicture: string;
    };
}

export const Info: React.FC<InfoProps> = () => {
    const params = useParams();

    const [image, setImage] = React.useState<string>('');
    const [coaches, setCoaches] = React.useState<Array<Player>>([]);
    const [players, setPlayers] = React.useState<Array<Player>>([]);
    const [name, setName] = React.useState<string>('');
    const [rank, setRank] = React.useState<string>('');

    React.useEffect(() => {
        apiclient
            .get(`team/info/${params.teamId}`)
            .then(res => {
                setImage(res.data.teamInfo.image);
                setName(res.data.teamInfo.name);
                setRank(res.data.teamInfo.rank);

                let a: Array<Player> = [];
                let b: Array<Player> = [];

                for (let player of res.data.players) {
                    if (player.function === 'Coach / Trainer') {
                        a.push(player);
                    } else {
                        b.push(player);
                    }
                }

                setPlayers(b);
                setCoaches(a);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    function translateFunction(item: string) {
        switch (item) {
            case 'Middle':
                return 'Midden';
            case 'Setter':
                return 'Spelverdeler';
            case 'Outside hitter':
                return 'Passer/loper';
            case 'Opposite hitter':
                return 'Diagonaal';
            case 'Libero':
                return item;
            case 'Coach / Trainer':
                return item;
            default:
                return '';
        }
    }

    function renderPlayers() {
        let elements: Array<JSX.Element> = [];

        for (let player of players) {
            elements.push(
                <User
                    name={`${player.user.firstName} ${player.user.lastName}`}
                    profilePicture={`${player.user.profilePicture}`}
                    subTitle={translateFunction(player.function)}
                />,
            );
        }

        return elements;
    }

    function renderCoaches() {
        let elements: Array<JSX.Element> = [];
        for (let coach of coaches) {
            elements.push(
                <User
                    name={`${coach.user.firstName} ${coach.user.lastName}`}
                    profilePicture={`${coach.user.profilePicture}`}
                    subTitle={coach.function}
                />,
            );
        }

        return elements;
    }

    return (
        <div className="flex">
            <Card className="w-5/6 mx-auto">
                <CardMedia
                    component="img"
                    src={`${apiclient.defaults.baseURL}/public/${image}`}
                />
                <CardContent>
                    <Typography variant="h5">{name}</Typography>
                    <Typography color="text.secondary">{rank}</Typography>
                    <Divider variant="middle" style={{margin: 10}} />
                    <div className="grid lg:grid-cols-3 gap-4">
                        <div className="col-span-2">
                            <Typography variant="h6">Spelers</Typography>
                            <div className="grid lg:grid-cols-2">
                                {renderPlayers()}
                            </div>
                        </div>
                        <div className="col-span-1">
                            <Typography variant="h6">Coaches</Typography>
                            {renderCoaches()}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
