import {Card, CardContent, Typography} from '@mui/material';
import React from 'react';
import apiclient from '../../Utilities/apiclient';
import {useNavigate} from 'react-router-dom';

type HomeProps = {};

interface Commissie {
    id: number;
    name: string;
    email: string;
}

export const Home: React.FC<HomeProps> = () => {
    const [commissies, setCommissies] = React.useState<Array<Commissie>>([]);

    const navigate = useNavigate();

    React.useEffect(() => {
        apiclient.get('committee').then(res => {
            setCommissies(res.data);
        });
    }, []);

    function render() {
        let elements: Array<JSX.Element> = [];

        for (let commissie of commissies) {
            let className = 'flex justify-center items-center flex-col';

            if (commissie.id === 1) {
                className += ' col-span-3';
            }

            elements.push(
                <Card
                    key={commissie.id}
                    className={className}
                    onClick={() => navigate(`${commissie.id}`)}>
                    <CardContent>
                        <Typography className="text-center" variant="h5">
                            {commissie.name}
                        </Typography>
                        <Typography
                            className="text-center"
                            sx={{mg: 1.5}}
                            color="text.secondary">
                            {commissie.email}
                        </Typography>
                    </CardContent>
                </Card>,
            );
        }

        return elements;
    }

    return (
        <div className="grid md:grid-cols-3 m-auto w-5/6 gap-5 mb-5">
            {render()}
        </div>
    );
};
