import React from 'react';
import apiclient from '../Utilities/apiclient';
import {AuthContext} from './AuthContext';

type AuthProviderProps = {};

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    let user: any = {};
    let accessToken: string = '';
    let refreshToken: string = '';

    function signin(email: string, password: string, cb: VoidFunction) {
        apiclient
            .post('auth/login', {
                email,
                password,
            })
            .then(res => {
                localStorage.setItem('accessToken', res.data.data.accessToken);
                localStorage.setItem(
                    'refreshToken',
                    res.data.data.refreshToken,
                );
                localStorage.setItem(
                    'user',
                    JSON.stringify(res.data.data.user),
                );
                cb();
            })
            .catch(err => {
                //TODO: Send message to snackbar component
                console.error(err);
            });
    }

    function signout(cb: VoidFunction) {
        localStorage.clear();
        cb();
    }

    function loggedIn() {
        if (!user || !accessToken || !refreshToken) {
            user = localStorage.getItem('user');
            accessToken = localStorage.getItem('accessToken') ?? '';
            refreshToken = localStorage.getItem('refreshToken') ?? '';
        }

        if (user && accessToken && refreshToken) {
            return true;
        } else {
            return false;
        }
    }

    let value = {user, accessToken, refreshToken, signin, signout, loggedIn};

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
