import React from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {AuthContext} from './AuthContext';

type RequireAuthProps = {};

export const RequireAuth = () => {
    let auth = React.useContext(AuthContext);
    let location = useLocation();

    if (!auth.loggedIn()) {
        return <Navigate to="/login" state={{from: location}} replace />;
    }

    return <Outlet />;
};
