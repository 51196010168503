import {Button, Card, CardContent, CardMedia, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import apiclient from '../../Utilities/apiclient';

type HomeProps = {};

interface Team {
    id: number;
    name: string;
    rank: string;
    gender: 'female' | 'men';
    image?: string;
}

export const Home: React.FC<HomeProps> = () => {
    const [gender, setGender] = React.useState<'female' | 'male'>('female');
    const [teams, setTeams] = React.useState<Array<Team>>([]);

    const navigate = useNavigate();

    React.useEffect(() => {
        apiclient
            .get('team/' + gender)
            .then(res => { 
                setTeams(res.data.teams);
            })
            .catch(err => {
                console.error(err);
            });
    }, [gender]);

    function render() {
        let elements: Array<JSX.Element> = [];

        for (let team of teams) {
            elements.push(
                <Card onClick={() => navigate(`${team.id}`)}>
                    <CardMedia
                        component="img"
                        sx={{height: 194, top: 20}}
                        src={`${apiclient.defaults.baseURL}/public/${team.image}`}
                    />
                    <CardContent>
                        <Typography variant="h5">{team.name}</Typography>
                        <Typography color="text.secondary">
                            {team.rank}
                        </Typography>
                    </CardContent>
                </Card>,
            );
        }

        return elements;
    }

    return (
        <div>
            <div className="grid grid-cols-2 m-auto w-5/6 gap-5 mb-5">
                <Button
                    onClick={() => setGender('female')}
                    variant={gender === 'female' ? 'contained' : 'outlined'}>
                    Dames
                </Button>
                <Button
                    onClick={() => setGender('male')}
                    variant={gender === 'male' ? 'contained' : 'outlined'}>
                    Heren
                </Button>
            </div>
            <div
                className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5 m-auto w-5/6"
                style={{height: '100%'}}>
                {render()}
            </div>
        </div>
    );
};
