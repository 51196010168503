import React from 'react';
import {Seasons} from '../../Smoelen/Profile';
import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

type SeasonProps = {
    data: Seasons;
};

export const Season: React.FC<SeasonProps> = ({data}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    };

    function translateFunction(item: string) {
        switch (item) {
            case 'Middle':
                return 'Midden';
            case 'Setter':
                return 'Spelverdeler';
            case 'Outside hitter':
                return 'Passer/loper';
            case 'Opposite hitter':
                return 'Diagonaal';
            case 'Libero':
                return item;
            case 'Coach / Trainer':
                return item;
            default:
                return '';
        }
    }

    function render() {
        let elements: Array<JSX.Element> = [];

        for (const tc of data.data) {
            let y: any;
            let func = tc.function;

            if (tc.committee) {
                y = tc.committee;
            } else if (tc.team) {
                y = tc.team;
                func = translateFunction(tc.function);
            }

            if (!y) {
                return;
            }

            elements.push(
                <ListItem key={tc.id} sx={{pl: 4}}>
                    <ListItemText primary={y.name} secondary={func} />
                </ListItem>,
            );
        }

        return elements;
    }

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemText primary={data.name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {render()}
                </List>
            </Collapse>
        </>
    );
};
