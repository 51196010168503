import {
    Button,
    Card,
    CardActions,
    CardContent,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import '../Styles/Card.css';
import '../Styles/Input.css';
import '../Styles/Global.css';
import {AuthContext} from './AuthContext';
import {useNavigate} from 'react-router-dom';

type LoginProps = {};

export const Login: React.FC<LoginProps> = () => {
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const auth = React.useContext(AuthContext);
    const navigate = useNavigate();

    function handleSumbit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        auth.signin(email, password, () => {
            navigate('/');
        });
    }

    return (
        <div className="flex h-screen">
            <div className="m-auto w-5/6">
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Login
                        </Typography>
                        <form id="loginForm" onSubmit={handleSumbit}>
                            <TextField
                                className="field"
                                label="Email"
                                variant="standard"
                                onChange={event => setEmail(event.target.value)}
                                fullWidth
                            />
                            <TextField
                                className="field"
                                label="Password"
                                type="password"
                                variant="standard"
                                onChange={event =>
                                    setPassword(event.target.value)
                                }
                                fullWidth
                            />
                        </form>
                    </CardContent>
                    <CardActions>
                        <Button
                            form="loginForm"
                            type="submit"
                            className="cardSubmit">
                            Login
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </div>
    );
};
