import {ArrowCircleRight, Key} from '@mui/icons-material';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import apiclient from '../Utilities/apiclient';

type HomeProps = {};

export const Home: React.FC<HomeProps> = () => {
    const [image, setImage] = React.useState<string>('');
    const [name, setName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [userId, setUserId] = React.useState<number>(1);

    const navigate = useNavigate();

    React.useEffect(() => {
        const storage = localStorage.getItem('user');

        if (!storage) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        const id = JSON.parse(storage).id;

        if (!id) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        apiclient.get(`user/${userId}`).then(res => {
            setName(`${res.data.firstName} ${res.data.lastName}`);
            setEmail(res.data.email);
            setImage(res.data.profilePicture);
            setUserId(id);
        });
    }, []);

    function logout() {
        localStorage.clear();
        navigate('/login');
    }

    return (
        <div className="w-5/6 mx-auto">
            <Card onClick={() => navigate(`/smoelen/user/${userId}`)}>
                <CardHeader
                    title={name}
                    subheader={email}
                    action={
                        <IconButton>
                            <ArrowCircleRight color="primary" />
                        </IconButton>
                    }
                    avatar={
                        <Avatar
                            src={`${apiclient.defaults.baseURL}/public/${image}`}
                        />
                    }
                />
            </Card>
            <Card style={{marginTop: 5}}>
                <CardContent>
                    <List
                        sx={{bgcolor: 'background.paper'}}
                        subheader={
                            <ListSubheader
                                component="div"
                                id="nested-list-subheader">
                                Settings
                            </ListSubheader>
                        }>
                        <ListItemButton>
                            <ListItemText primary="Wachtwoord veranderen" />{' '}
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="Gegevens aanpassen" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="Profiel foto aanpassen" />
                        </ListItemButton>
                    </List>
                </CardContent>
            </Card>
            <Card onClick={() => logout()} style={{marginTop: 5}}>
                <CardHeader
                    title="Uitloggen"
                    avatar={<Key color="primary" />}
                />
            </Card>
        </div>
    );
};
