import {
    BottomNavigation,
    BottomNavigationAction,
    Paper,
    useTheme,
} from '@mui/material';
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import CasinoIcon from '@mui/icons-material/Casino';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SettingsIcon from '@mui/icons-material/Settings';

type TabBarProps = {};

export const TabBar: React.FC<TabBarProps> = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [value, setValue] = React.useState<string>(window.location.pathname);

    return (
        <Paper
            sx={{position: 'fixed', bottom: 0, left: 0, right: 0}}
            elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, value) => {
                    setValue(value);
                    navigate(value);
                }}
                style={{backgroundColor: theme.palette.primary.main}}>
                <BottomNavigationAction
                    label="Smoelen"
                    value="/"
                    component={Link}
                    to="/home"
                    icon={<GroupsIcon />}
                    style={{color: theme.palette.primary.contrastText}}
                />
                <BottomNavigationAction
                    label="Protototo"
                    value="/maintenance"
                    icon={<CasinoIcon />}
                    style={{color: theme.palette.primary.contrastText}}
                />
                <BottomNavigationAction
                    label="Documenten"
                    value="/maintenance"
                    icon={<InsertDriveFileIcon />}
                    style={{color: theme.palette.primary.contrastText}}
                />
                <BottomNavigationAction
                    label="Settings"
                    value="/settings"
                    icon={<SettingsIcon />}
                    style={{color: theme.palette.primary.contrastText}}
                />
            </BottomNavigation>
        </Paper>
    );
};
