import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Router, Routes, Route} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {Home} from './Home';
import {Login} from './Auth/Login';
import App from './App';
import {RequireAuth} from './Auth/RequireAuth';
import theme from './Theme';
import {AuthProvider} from './Auth/AuthProvider';
import {ThemeProvider} from '@mui/material';
import {Home as TeamHome} from './Smoelen/Teams/Home';
import {Info as TeamInfo} from './Smoelen/Teams/Info';
import {Home as CommissieHome} from './Smoelen/Commissie/Home';
import {Info as CommissieInfo} from './Smoelen/Commissie/Info';
import {Profile} from './Smoelen/Profile';
import history from './Components/Navigation/History';
import {CustomRouter} from './Components/Navigation/CustomRouter';
import {Home as SettingsHome} from './Settings/Home';
import {Maintenance} from './Components/Maintenance';

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <CustomRouter history={history}>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route path="home" element={<Home />} />
                        <Route path="login" element={<Login />} />
                        <Route path="smoelen/" element={<RequireAuth />}>
                            <Route path="teams" element={<TeamHome />} />
                            <Route
                                path="teams/:teamId"
                                element={<TeamInfo />}
                            />
                            <Route
                                path="commissies"
                                element={<CommissieHome />}
                            />
                            <Route
                                path="commissies/:commissieId"
                                element={<CommissieInfo />}
                            />
                            <Route path="user/:userId" element={<Profile />} />
                        </Route>
                        <Route path="settings/" element={<RequireAuth />}>
                            <Route path="" element={<SettingsHome />} />
                        </Route>
                    </Route>
                    <Route path="maintenance" element={<Maintenance />} />
                </Routes>
            </CustomRouter>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
