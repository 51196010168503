import {Avatar, Box, Typography} from '@mui/material';
import React from 'react';
import themeOptions from '../../Theme';
import apiclient from '../../Utilities/apiclient';

type UserProps = {
    name: string;
    subTitle?: string;
    profilePicture: string;
};

export const User: React.FC<UserProps> = ({name, profilePicture, subTitle}) => {
    return (
        <div className="flex my-5">
            <Avatar
                className="flex-initial w-100 mr-5"
                src={`${apiclient.defaults.baseURL}/public/${profilePicture}`}
                sx={{width: 72, height: 72}}
            />
            <div className="flex-1 w-100">
                <Typography variant="h5">{name}</Typography>
                <Typography color="red">{subTitle}</Typography>
            </div>
        </div>
    );
};
