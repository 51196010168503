import {Typography} from '@mui/material';
import React from 'react';

type MaintenanceProps = {};

export const Maintenance: React.FC<MaintenanceProps> = () => {
    return (
        <div>
            <div className="absolute bg-zinc-800 bottom-12 left-1/2 transform -translate-x-1/2">
                <Typography variant="h4" color="white">
                    Oops... Onderhoud!
                </Typography>
                <Typography color="white">
                    Er wordt momenteel onderhoud gepleegd op deze webpagina. Als
                    je denkt dat dit een fout is, stuur dan een mailtje naar:
                    webcie@usvprotos.nl
                </Typography>
            </div>
            <div className="maintenance-container h-screen w-screen"></div>
        </div>
    );
};
