import React from 'react';
import {Router} from 'react-router-dom';
import {History} from 'history';

type CustomRouterProps = {
    history: History;
};

export const CustomRouter: React.FC<CustomRouterProps> = ({
    history,
    children,
}) => {
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location,
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            location={state.location}
            navigationType={state.action}
            navigator={history}
            children={children}
        />
    );
};
