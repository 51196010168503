import {ThemeOptions} from '@mui/material';

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#d0211c',
        },
        secondary: {
            main: '#f50057',
        },
    },
};
export default themeOptions;
