import {Card, Typography, CardContent} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from './Auth/AuthContext';

type HomeProps = {};

export const Home: React.FC<HomeProps> = () => {
    const navigate = useNavigate();
    let auth = React.useContext(AuthContext);

    React.useEffect(() => {
        if (!auth.loggedIn()) {
            localStorage.clear();
            return navigate('/login');
        }
    }, []);

    function clicked(location: string) {
        navigate(location);
    }

    return (
        <div className="flex justify-center items-center">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/6">
                <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-5">
                    <Card onClick={() => clicked('/smoelen/teams')}>
                        <CardContent>
                            <Typography className="text-center" variant="h4">
                                Teams
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card onClick={() => clicked('/smoelen/commissies')}>
                        <CardContent>
                            <Typography className="text-center" variant="h4">
                                Commissies
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card onClick={() => clicked('/maintenance')}>
                        <CardContent>
                            <Typography className="text-center" variant="h4">
                                VCPS
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};
