import React from 'react';
import apiclient from '../../Utilities/apiclient';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate} from 'react-router-dom';

type SearchProps = {};

interface User {
    id: number;
    firstName: string;
    lastName: string;
    userTeamSeason: Array<Team>;
}

interface Team {
    id: number;
    function: string;
    team: {
        name: string;
    };
}

export const Search: React.FC<SearchProps> = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<readonly User[]>([]);
    const loading = open && options.length === 0;

    const navigate = useNavigate();
    const autoComplete = React.useRef();

    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }

        (async () => {
            const res = await apiclient.get('search/user/*');

            if (active) {
                setOptions(res.data.users);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            ref={autoComplete}
            sx={{width: 300}}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) =>
                `${option.firstName} ${option.lastName}` ===
                `${value.firstName} ${value.lastName}`
            }
            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
            options={options}
            loading={loading}
            clearOnBlur
            onChange={(event, value) => {
                if (value) {
                    navigate(`/smoelen/user/${value.id}`);
                }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    sx={{backgroundColor: 'background.main'}}
                    placeholder="Vind een lid"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};
